import '../styles/globals.css'
import {createGlobalStyle, ThemeProvider} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    box-sizing: border-box;
  }
`

const theme = {
  colors: {
    primary: '#000000',
  },
}

export default function App({Component, pageProps}) {
  return (
    <>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

